import React, { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import ReactDOM from "react-dom";
import classNames from "classnames";
import type { NavigationSection_Item } from "@motain/xpa-proto-files-web/lib/types/navigation_bar";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import ChevronLeftIcon from "@/public/next-assets/chevron-left.svg";
import { useLockBodyScroll } from "@/utils/hooks/useLockBodyScroll";
import { isNullable } from "@/types/isNullable";
import {
  RudderstackEventName,
  StreamName,
  trackingService,
} from "@/modules/analytics";
import {
  EventType,
  ServerParameterType,
  TrackerType,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import styles from "./MobileMenu.module.scss";
import { isNonNullable } from "@/types/isNonNullable";
import type {
  LinkMenuItem,
  SubMenu,
  SubNavItem,
  SubNavType,
} from "../Header.types";
import { LoadingProgressBar } from "@/components/loading-progress-bar/LoadingProgressBar";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import Link from "next/link";

export const MobileMenu: React.FC<{
  subMenuData: SubNavType;
  onClick: () => void; // When the user clicks on the menu items we will close the menu
}> = ({ subMenuData, onClick }) => {
  const { t, lang } = useTranslation("web-payments");
  const [subMenu, setSubMenu] = useState<SubMenu | undefined>(undefined);

  useLockBodyScroll();

  const menuData: Array<LinkMenuItem | SubNavItem> = React.useMemo(() => {
    const data = [
      {
        link: {
          name: t`MATCHES`,
          urlPath: t`ONEFOOTBALL_MATCHES_PATH`,
          trackingEvents: [
            {
              type: EventType.EVENT_CLICK,
              name: RudderstackEventName.NavBarInteraction,
              schema: "",
              clientParameterRequests: [],
              typedServerParameters: {
                screen_accessed: {
                  type: ServerParameterType.STRING,
                  value: StreamName.MatchesStream,
                },
              },
              trackers: [
                {
                  type: TrackerType.TRACKER_RUDDERSTACK,
                  trackerParameters: {},
                },
              ],
            },
          ],
        },
      },
      {
        submenu: {
          name: t`TEAMS`,
          sections: subMenuData.teams?.sections,
        },
      },
      {
        submenu: {
          name: t`COMPETITIONS`,
          sections: subMenuData.competitions?.sections,
        },
      },
      {
        link: {
          name: t`TV_HEADER`,
          urlPath: `/${lang}/tv-hub`,
          trackingEvents: [
            {
              type: EventType.EVENT_CLICK,
              name: RudderstackEventName.NavBarInteraction,
              schema: "",
              clientParameterRequests: [],
              typedServerParameters: {
                screen_accessed: {
                  type: ServerParameterType.STRING,
                  value: StreamName.TvHub,
                },
              },
              trackers: [
                {
                  type: TrackerType.TRACKER_RUDDERSTACK,
                  trackerParameters: {},
                },
              ],
            },
          ],
        },
      },
    ];

    if (lang === "en" && envBasedConfig.featureFlags.extraTime) {
      data.push({
        link: {
          name: "Extra Time",
          urlPath: "/en/extra-time",
          trackingEvents: [
            {
              type: EventType.EVENT_CLICK,
              name: RudderstackEventName.NavBarInteraction,
              schema: "",
              clientParameterRequests: [],
              typedServerParameters: {
                screen_accessed: {
                  type: ServerParameterType.STRING,
                  value: StreamName.ExtraTime,
                },
              },
              trackers: [
                {
                  type: TrackerType.TRACKER_RUDDERSTACK,
                  trackerParameters: {},
                },
              ],
            },
          ],
        },
      });
    }
    return data;
  }, [
    t,
    subMenuData.teams?.sections,
    subMenuData.competitions?.sections,
    lang,
  ]);

  useEffect(() => {
    // Update the open submenu when new data arrives
    if (subMenu === undefined || subMenu.sections !== undefined) {
      return;
    }

    for (const menuItem of menuData) {
      if ("submenu" in menuItem && menuItem.submenu?.name === subMenu.name) {
        setSubMenu(menuItem.submenu);
        break;
      }
    }
  }, [menuData, subMenu]);

  const handleClick = (item: NavigationSection_Item | LinkMenuItem) => {
    onClick();
    if (isNonNullable(item.link)) {
      trackingService.sendXpaTracking(
        item.link.trackingEvents,
        EventType.EVENT_CLICK,
      );
    }
  };

  const renderSubMenuContent = (submenu: SubMenu) => {
    return (
      <>
        <button
          className={classNames("title-7-regular", styles.subMenuButton)}
          onClick={() => {
            setSubMenu(undefined);
          }}
        >
          <div className={classNames("text-4", styles.backIcon)}>
            <ChevronLeftIcon />
          </div>
          {submenu.name}
        </button>
        {isNullable(submenu.sections) ? (
          <LoadingProgressBar />
        ) : (
          submenu.sections.map((section) => {
            return (
              <div key={section.title} className={styles.section}>
                <p className={classNames("title-7-medium", styles.title)}>
                  {section.title}
                </p>
                <ul className={styles.linksContainer}>
                  {section.items.map((item) => (
                    <li
                      className={styles.subMenuListItem}
                      key={item.link?.urlPath}
                    >
                      {renderLinkItem(item)}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })
        )}
      </>
    );
  };

  const renderLinkItem = (
    linkItem: LinkMenuItem | NavigationSection_Item,
    isMainLink = false,
  ) => {
    if (isNullable(linkItem.link)) {
      return null;
    }

    return (
      <Link
        className={classNames(
          "title-7-bold",
          styles.mobileLink,
          isMainLink && styles.mainLink,
        )}
        href={linkItem.link.urlPath}
        onClick={() => {
          handleClick(linkItem);
        }}
      >
        {isNonNullable(linkItem.logo) && (
          <ImageWithFallback
            alt={linkItem.logo.alt}
            src={linkItem.logo.path}
            width={24}
            height={24}
            className={styles.linkLogo}
          />
        )}
        <p className={styles.linkName}>{linkItem.link.name}</p>
        {isMainLink && (
          <ImageWithFallback
            alt=""
            src="https://images.onefootball.com/cw/icons/arrow-right-light.svg"
            darkModeSrc="https://images.onefootball.com/cw/icons/arrow-right-dark.svg"
            width={24}
            height={24}
          />
        )}
      </Link>
    );
  };

  const renderSubMenu = (submenu: SubMenu) => {
    return (
      <button
        className={classNames("title-7-bold", styles.subMenuButton)}
        onClick={() => {
          setSubMenu(submenu);
        }}
      >
        <span>{submenu.name}</span>
        <ImageWithFallback
          alt=""
          src="https://images.onefootball.com/cw/icons/arrow-right-light.svg"
          darkModeSrc="https://images.onefootball.com/cw/icons/arrow-right-dark.svg"
          width={24}
          height={24}
        />
      </button>
    );
  };

  const renderMenu = (data: Array<LinkMenuItem | SubNavItem>) => {
    return (
      <ul>
        {data.map((item) => {
          if ("link" in item && isNonNullable(item.link)) {
            return (
              <li className={styles.mainMenuListItem} key={item.link.urlPath}>
                {renderLinkItem(item, true)}
              </li>
            );
          }

          if ("submenu" in item && isNonNullable(item.submenu)) {
            return (
              <li className={styles.mainMenuListItem} key={item.submenu.name}>
                {renderSubMenu(item.submenu)}
              </li>
            );
          }

          return null;
        })}
      </ul>
    );
  };

  const menu = (
    <nav
      className={classNames(
        styles.mobileMenu,
        subMenu !== undefined && styles.subMenuOpened,
      )}
    >
      {isNonNullable(subMenu)
        ? renderSubMenuContent(subMenu)
        : renderMenu(menuData)}
    </nav>
  );

  return ReactDOM.createPortal(menu, document.body);
};
