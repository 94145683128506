// type union of all stream names of the website. Mainly required for tracking.
import { useEffect } from "react";
import { create, defaulted, enums } from "superstruct";
import { isNonNullable } from "@/types/isNonNullable";
import { remoteLogger } from "@/utils/remoteLogger";
import { useRouter } from "next/router";

// TODO: remove this enum when all data come from backend. This data comes from metadata of pages.
export enum StreamName {
  AeraExt = "Aera-ext",
  BookmarksStream = "Bookmarks",
  BrowseCompetitions = "BrowseCompetitions",
  BrowseCountries = "BrowseCountries",
  BrowsePlayers = "BrowsePlayers",
  BrowseTeams = "BrowseTeams",
  ClipsDetail = "ClipsDetail",
  CompetitionMatchesStream = "CompetitionMatches",
  CompetitionResultsStream = "CompetitionResults",
  CompetitionStream = "CompetitionNews",
  CompetitionOfficialNews = "CompetitionOfficialNews",
  CompetitionTableStream = "CompetitionTable",
  CompetitionTransfersStream = "CompetitionTransfers",
  DeferredDeepLink = "DeferredDeepLink",
  DeleteAccount = "DeleteAccount",
  DirectoryAllCompetitionsStream = "DirectoryAllCompetitionsStream",
  DirectoryAllTeamsStream = "DirectoryAllTeamsStream",
  Error = "Error",
  ForgotPassword = "ForgotPassword",
  ExtraTime = "ExtraTime",
  HomeStream = "Home",
  Licenses = "Licenses",
  MatchCheckout = "MatchCheckout",
  MatchesStream = "MatchesAll",
  MatchStream = "MatchOverview",
  MissingConsent = "MissingConsent",
  NewsDetails = "ArticleDetail",
  NotFound = "NotFound",
  OnboardingNational = "OnboardingNational",
  OnboardingTeam = "OnboardingTeam",
  PaymentInProgress = "PaymentInProgress",
  PlayerNewsStream = "PlayerNews",
  PlayerSeasonStream = "PlayerDetails",
  PlayerStream = "PlayerSeason",
  ProfileAccount = "ProfileAccount",
  ProfileFollowing = "ProfileFollowing",
  ProfilePurchases = "ProfilePurchases",
  ProfileSettings = "ProfileSettings",
  SearchStream = "SearchStream",
  SignIn = "SignIn",
  SignUp = "SignUp",
  TeamMatchesStream = "TeamMatches",
  TeamResultsStream = "TeamResults",
  TeamStream = "TeamNews",
  TeamTransfersStream = "TeamTransfers",
  TeamOfficialNewsStream = "OfficialClub",
  ThankYou = "ThankYou",
  TransferStream = "TransferDetails",
  TvCodeInput = "TvCodeInput",
  TvHub = "tvHub",
  OFTV = "OFTV",
  TvThankYou = "TvThankYou",
  unknown = "unknown",
  VerifyEmail = "VerifyEmail",
  TeamOverview = "TeamOverview",
}

export type EntityStreamName = string | StreamName;

const streamNameTypeObject = defaulted(
  enums(Object.values(StreamName)),
  StreamName.unknown,
);

const defaultedCreate = (prevStreamNameRaw: string): EntityStreamName => {
  try {
    if (prevStreamNameRaw in StreamName) {
      return create(prevStreamNameRaw, streamNameTypeObject);
    }

    return prevStreamNameRaw;
  } catch (error) {
    remoteLogger.error(
      `Stream name service: ${prevStreamNameRaw} is not a valid stream name`,
    );

    return StreamName.unknown;
  }
};

/**
 * A service that provides a way to get the current and previous stream names.
 * Required mainly for tracking purposes.
 */
export const streamName: Readonly<
  Record<"previous" | "current", EntityStreamName>
> = {
  get previous() {
    if (typeof window !== "undefined") {
      const prevStreamNameRaw = sessionStorage.getItem("prevStreamName");
      if (isNonNullable(prevStreamNameRaw)) {
        return defaultedCreate(prevStreamNameRaw);
      }
    }

    return StreamName.unknown;
  },

  get current() {
    if (typeof window !== "undefined") {
      const currentStreamNameRaw = sessionStorage.getItem("currentStreamName");
      if (isNonNullable(currentStreamNameRaw)) {
        return defaultedCreate(currentStreamNameRaw);
      }
    }

    return StreamName.unknown;
  },
};

/**
 * A custom hook that sets the current stream name only once on a component
 * mount.
 */
export function useStreamNameForThisPage(name: EntityStreamName): void {
  const router = useRouter();

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    try {
      const prevStreamNameRaw = sessionStorage.getItem("currentStreamName");

      if (isNonNullable(prevStreamNameRaw)) {
        const previousStreamName = defaultedCreate(prevStreamNameRaw);
        sessionStorage.setItem("prevStreamName", previousStreamName);
      }

      sessionStorage.setItem("currentStreamName", name);
    } catch (err) {
      remoteLogger.error(err);
    }
  }, [name, router.asPath]);
}
